export const colors = {
  n01: '#ffffff',
  n03: '#DBDBDB',
  n04: '#AAAAAA',
  n06: '#404040',
  n07: '#151414',

  p05: '#A49B8C',
  p06: '#8D2D17',
};
