import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

export const SEO = ({ lang, meta }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const { description, title } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          content: description,
          name: `description`,
        },
        {
          content: title,
          property: `og:title`,
        },
        {
          content: description,
          property: `og:description`,
        },
        {
          content: `website`,
          property: `og:type`,
        },
      ].concat(meta)}
      title={title}
      titleTemplate={title}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};
