import { GlobalStyle } from '../theme/components';
import { SEO } from './SEO';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

export const Layout = (props) => {
  const { children } = props;

  return (
    <>
      <SEO />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};
