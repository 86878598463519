/* eslint-disable sort-keys */
const weights = {
  bold: 700,
  semibold: 600,
  regular: 400,
};

const families = {
  sans: 'Raleway',
};

export const fonts = {
  families,
  weights,
};
