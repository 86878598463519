import { colors, fonts, generator } from './variables';

const theme = {
  colors,
  fonts,

  generator,
};

export { colors, fonts, generator };

export default theme;
